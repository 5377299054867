import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { menu, privateMenu } from '../../data/menu'
import logo from '../../static/logo-red.svg'
import { UserContext } from '../UserContext'

import FacebookIcon from '../../images/social/facebook.svg'
import TwitterIcon from '../../images/social/twitter.svg'

const Header = ({ location }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isLoggedIn } = useContext(UserContext)
  const [menuOptions, setMenuOptions] = useState(menu)
  const [currentlyActive, setActive] = useState(0)
  const setMenuOption = option => () => {
    const currentOpt = [8, 9, 10].includes(option) ? 7 : option
    setActive(currentOpt)
  }
  useEffect(() => {
    if (isLoggedIn) {
      setMenuOptions([
        ...menu.slice(0, 1),
        ...privateMenu,
        ...menu.slice(3, menu.length),
      ])
    } else {
      setMenuOptions(menu)
    }
  }, [isLoggedIn])

  useEffect(() => {
    const { pathname } = location
    const newPath = pathname !== '/' ? pathname.replace(/\/$/, '') : '/'
    const activeOption = menuOptions.find(option => {
      if (option.submenu && option.submenu.length > 0) {
        return option.submenu.find(childOption => childOption.link === newPath)
      }
      return option.link === newPath
    })
    if (activeOption) {
      if ([8, 9, 10].includes(activeOption)) setActive(7)
      else setActive(activeOption.id)
    } else setActive(0)
  }, [menuOptions, location])

  const toggle = () => setIsOpen(!isOpen)

  const navigateTo = link => () => {
    if (isOpen) setIsOpen(false)
    navigate(link)
  }

  return (
    <header>
      <Navbar color="faded" light expand="md">
        <NavbarBrand onClick={setMenuOption(0)} href="/">
          <div className="logo">
            <img src={logo} alt="cboxera.com" />
          </div>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mx-auto" navbar>
            {menuOptions.map(item => (
              <span key={item.id}>
                {item.submenu.length ? (
                  <UncontrolledDropdown
                    nav
                    inNavbar
                    active={currentlyActive === item.id}
                    className="ml-3"
                  >
                    <DropdownToggle nav caret className="option">
                      {item.name}
                    </DropdownToggle>
                    <DropdownMenu direction="left">
                      {item.submenu.map(subitem => (
                        <DropdownItem
                          key={subitem.id}
                          onClick={navigateTo(subitem.link)}
                        >
                          {subitem.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : (
                  <NavItem className="ml-3" key={item.id}>
                    <NavLink
                      className="option"
                      onClick={navigateTo(item.link)}
                      active={currentlyActive === item.id}
                    >
                      <i className={`${item.icon} pr-1`} />
                      {item.name}
                    </NavLink>
                  </NavItem>
                )}
              </span>
            ))}
          </Nav>
        </Collapse>
      </Navbar>
      <div className="social-links">
        <div>
          <a
            href="https://facebook.com/cboxera"
            title="facebook"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={FacebookIcon} alt="facebook icon" />
          </a>
        </div>
        <div>
          <a
            href="https://twitter.com/CBOXERA"
            title="twitter"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={TwitterIcon} alt="twitter icon" />
          </a>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
}

export default Header
