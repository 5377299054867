import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { Button } from 'reactstrap'

import { PREMIUM, LOGIN, REGISTER, DOWNLOADER } from '../../data/constants'
import { UserContext } from '../UserContext'
// import styles from './index.module.scss'

const UpgradeBtn = ({ isLoggedIn }) => (
  <div className="panel-footer">
    {isLoggedIn ? (
      <Button className="btn btn-danger" onClick={() => navigate(PREMIUM)}>
        Upgrade
      </Button>
    ) : (
      <Button className="btn btn-danger" onClick={() => navigate(LOGIN)}>
        Login & Upgrade
      </Button>
    )}
  </div>
)

// eslint-disable-next-line react/no-multi-comp
const Plans = () => {
  const { isLoggedIn } = useContext(UserContext)
  return (
    <section className="pricingtables">
      <div className="row">
        <div className="col-sm-12">
          <h2 className="premium-header">
            <i className="fas fa-gift px-2"></i>
            Premium Plans
          </h2>

          <p>
            All Download Links we provide are high speed, resumable and
            dedicated server links. We don't host or save any files on our
            servers.
          </p>
        </div>
      </div>
      <div className="row spacing-70 no-gutter">
        <div className="col-sm-3">
          <div className="panel panel-info">
            <div className="panel-heading">
              <h3 className="text-center">Free Plan</h3>
            </div>
            <div className="panel-body text-center">
              <h4>$ 0</h4>
              <span className="per">Lifetime</span>
            </div>
            <ul className="text-center">
              <li>Lifetime Free</li>
              <li>Limited Hosts</li>
              <li>No Streaming</li>
              <li>
                No Download History <br />
                <sup>(disabled by default)</sup>
              </li>
              <li>No Save links</li>
              <li>25 GB Daily Bandwidth</li>
              <li>Advertisements</li>
              <li>24/7 support</li>
            </ul>
            <div className="panel-footer">
              {isLoggedIn ? (
                <Link className="btn btn-primary btn-md" to={DOWNLOADER}>
                  Download Links
                </Link>
              ) : (
                <Link className="btn btn-primary btn-md" to={REGISTER}>
                  Register Now
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="panel panel-info">
            <div className="panel-heading">
              <h3 className="text-center">1 Month</h3>
            </div>
            <div className="panel-body text-center">
              <h4>$ 7</h4>
              <span className="per">30 DAYS PREMIUM</span>
              {/* <div className={styles.extraOfferDays}>+7 Days Free</div> */}
            </div>
            <ul className="text-center">
              <li>1 Month Premium</li>
              <li>50+ Hosts Support</li>
              <li>Unlimited Streaming</li>
              <li>
                Download History <br />
                <sup>(enable/disable this option)</sup>
              </li>
              <li>Save Original links</li>
              <li>150 GB Daily Bandwidth</li>
              <li>No Advertisements</li>
              <li>24/7 support</li>
            </ul>
            <UpgradeBtn isLoggedIn={isLoggedIn} />
          </div>
        </div>
        <div className="col-sm-3">
          <div className="panel panel-info">
            <div className="panel-heading">
              <h3 className="text-center">2 Months</h3>
            </div>
            <div className="panel-body text-center">
              <h4>$ 13</h4>
              <span className="per">60 DAYS PREMIUM</span>
              {/* <div className={styles.extraOfferDays}>+15 Days Free</div> */}
            </div>
            <ul className="text-center">
              <li>2 Months Premium</li>
              <li>50+ Hosts Support</li>
              <li>Unlimited Streaming</li>
              <li>
                Download History <br />
                <sup>(enable/disable this option)</sup>
              </li>
              <li>Save Original links</li>
              <li>150 GB Daily Bandwidth</li>
              <li>No Advertisements</li>
              <li>24/7 support</li>
            </ul>
            <UpgradeBtn isLoggedIn={isLoggedIn} />
          </div>
        </div>
        <div className="col-sm-3 most-popular">
          <div className="panel panel-info">
            <div className="panel-heading">
              <h3 className="text-center">3 Months</h3>
            </div>
            <div className="panel-body text-center">
              <h4>$ 19</h4>
              <span className="per">90 DAYS PREMIUM</span>
              {/* <div className={styles.extraOfferDays}>+30 Days Free</div> */}
            </div>
            <ul className="text-center">
              <li>3 Months Premium</li>
              <li>50+ Hosts Support</li>
              <li>Unlimited Streaming</li>
              <li>
                Download History <br />
                <sup>(enable/disable this option)</sup>
              </li>
              <li>Save Original links</li>
              <li>150 GB Daily Bandwidth</li>
              <li>No Advertisements</li>
              <li>24/7 support</li>
            </ul>
            <UpgradeBtn isLoggedIn={isLoggedIn} />
          </div>
        </div>
      </div>
    </section>
  )
}

UpgradeBtn.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
}

export default Plans
