import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Col, FormGroup, Label, Input, Spinner, Badge } from 'reactstrap'
import { PaymentsContext } from './PaymentsContext'

const PaymentMethods = ({ onChange, gateway }) => {
  const paymentoptions = useContext(PaymentsContext)
  return (
    <FormGroup tag="fieldset" row>
      {/* eslint-disable */}
      {paymentoptions.loading && !paymentoptions.loaded && (
        <div className="text-center">
          <Spinner
            style={{ width: '3rem', height: '3rem' }}
            className="mt-4 mb-1"
          />
          <div className="text-center mb-4">Please wait..</div>
        </div>
      )}
      {/* eslint-enable */}
      {paymentoptions.loaded && (
        <Col sm={10}>
          {/* {paymentoptions.data.includes(1) && (
            <FormGroup check className="pb-4">
              <Label check>
                <Input
                  type="radio"
                  name="gateway"
                  value="paypal"
                  onChange={onChange}
                  checked={gateway === 'paypal'}
                />{' '}
                <i className="fab fa-paypal fa-2x" /> Paypal{'  '}
                <Badge color="warning p-1" pill>
                  <i className="fas fa-parachute-box pr-1" />
                  Recommended
                </Badge>
              </Label>
            </FormGroup>
          )} */}
          {/* <FormGroup check>
            <Label check className="pb-4">
              <Input
                type="radio"
                name="gateway"
                value="pmu-card"
                onChange={onChange}
                checked={gateway === 'pmu-card'}
              />{' '}
              <i className="far fa-credit-card fa-2x" /> Credit/Debit Card{' '}
              <small className="text-danger">(Reseller - Premium.us) </small>
              <Badge color="warning p-1" pill>
                <i className="fas fa-parachute-box pr-1" />
                New
              </Badge>
            </Label>
          </FormGroup> */}
          <FormGroup check>
            <Label check className="pb-4">
              <Input
                type="radio"
                name="gateway"
                value="stripe"
                onChange={onChange}
                checked={gateway === 'stripe'}
              />{' '}
              <i className="fab fa-cc-stripe fa-2x align-middle ml-2" />{' '}
              Credit/Debit Card
              <Badge color="warning p-1 ml-2" pill>
                <i className="fas fa-star pr-1" />
                New (Instant Activation)
              </Badge>
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check className="pb-4">
              <Input
                type="radio"
                name="gateway"
                value="fss-card"
                onChange={onChange}
                checked={gateway === 'fss-card'}
              />{' '}
              <i className="far fa-credit-card fa-2x align-middle ml-2" />{' '}
              Credit Card{' '}
              <small className="text-danger">
                (Reseller - Filesharingshop.com)
              </small>
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check className="pb-4">
              <Input
                type="radio"
                name="gateway"
                value="fss-paypal"
                onChange={onChange}
                checked={gateway === 'fss-paypal'}
              />{' '}
              <i className="fab fa-paypal fa-2x align-middle ml-2" /> Paypal{' '}
              <small className="text-danger">
                (Reseller - Filesharingshop.com)
              </small>{' '}
            </Label>
          </FormGroup>
          {/* <FormGroup check>
            <Label check className="pb-4">
              <Input
                type="radio"
                name="gateway"
                value="bitcoin"
                onChange={onChange}
                checked={gateway === 'bitcoin'}
              />{' '}
              <i className="fab fa-bitcoin fa-2x" /> Bitcoin
            </Label>
          </FormGroup> */}
          <FormGroup check>
            <Label check className="pb-2">
              <Input
                type="radio"
                name="gateway"
                value="paytm"
                onChange={onChange}
                checked={gateway === 'paytm'}
              />{' '}
              <i className="fas fa-rupee-sign fa-2x align-middle ml-2" /> PayTM
              (Only for Indians)
            </Label>
          </FormGroup>
        </Col>
      )}
    </FormGroup>
  )
}

PaymentMethods.propTypes = {
  onChange: PropTypes.func.isRequired,
  gateway: PropTypes.string.isRequired,
}

export default PaymentMethods
