import React, { createContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { isUserLoggedIn } from '../../selectors/login'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const isLoggedIn = useSelector(isUserLoggedIn)

  const userInfo = useMemo(() => ({ isLoggedIn }), [isLoggedIn])

  return (
    <UserContext.Provider value={userInfo}>{children}</UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
