import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { SEOMetaData } from '../../data/seo'
function SEO({ lang, page = 'default' }) {
  const seoContent = SEOMetaData[page]
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={`${seoContent.title}`}
      meta={[
        {
          name: 'keywords',
          content: `${seoContent.keywords}`,
        },
        {
          name: 'description',
          content: `${seoContent.description}`,
        },
        {
          itemprop: 'name',
          content: `${seoContent.keywords}`,
        },
        {
          itemprop: 'description',
          content: `${seoContent.description}`,
        },
        {
          itemprop: 'image',
          content: `https://www.cboxera.com/static/logo.png`,
        },
        {
          property: 'og:url',
          content: `https://www.cboxera.com${seoContent.path}`,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:title',
          content: `${seoContent.title}`,
        },
        {
          property: 'og:description',
          content: `${seoContent.description}`,
        },
        {
          property: 'og:image',
          content: 'https://www.cboxera.com/static/logo.png',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: `${seoContent.title}`,
        },
        {
          name: 'twitter:description',
          content: `${seoContent.description}`,
        },
        {
          name: 'twitter:image',
          content: 'https://www.cboxera.com/static/logo.png',
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
}

SEO.propTypes = {
  page: PropTypes.string.isRequired,
  lang: PropTypes.string,
}

export default SEO
