import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Location } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import { UserProvider } from './UserContext'

import 'bootstrap/dist/css/bootstrap.css'

import Header from './Header/Header'
import Footer from './Footer/index'
import CustomModal from './Modal'
import UpdatesModal from './Updates'

import { DOWNLOADER, LOGIN } from '../data/constants'

import { showModal } from '../reducers/modal'
import { loadUserInfo } from '../reducers/user'
import { isUserLoggedIn } from '../selectors/login'
// import WishesBanner from './WishesBanner'

const IndexWrapper = ({ children }) => {
  const dispatch = useDispatch()
  const { show } = useSelector(state => state.modal)
  const isLoggedIn = useSelector(isUserLoggedIn)
  const handleReload = () => {
    navigate(`${LOGIN}/?redirect=${DOWNLOADER}`)
    dispatch(showModal(false))
  }

  useEffect(() => {
    if (isLoggedIn) dispatch(loadUserInfo())
  }, [isLoggedIn])

  return (
    <UserProvider>
      <Location>
        {({ location }) => (
          <div className="main-container">
            <a href="#main-content" className="skip-link">
              Skip to main content
            </a>
            <Header location={location} />
            <UpdatesModal />
            <CustomModal
              open={show}
              title="Session Expired !"
              message="Your Session Expired. Please login again."
              buttonName="Reload"
              onClick={handleReload}
            />
            <div id="main-content">{children}</div>
            {/* <WishesBanner /> */}
            <Footer />
          </div>
        )}
      </Location>
    </UserProvider>
  )
}

IndexWrapper.propTypes = {
  children: PropTypes.any.isRequired,
}

export default IndexWrapper
