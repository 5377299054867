import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Button } from 'reactstrap'
import { navigate, Link } from 'gatsby'

import { REGISTER, HOST_STATUS, DOWNLOADER } from '../data/constants'

import HowToDownload from '../components/Home/HowToDownload'
import { isUserLoggedIn } from '../selectors/login'

const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ''

const FilehostTemplate = ({ pageContext }) => {
  const [hostName] = pageContext.name.split('.')
  const hostWithExtension = pageContext.name
  const hostWithExtensionCaps = capitalize(hostWithExtension)
  const isLoggedIn = useSelector(isUserLoggedIn)

  function handleDownloadClick() {
    navigate(isLoggedIn ? DOWNLOADER : REGISTER)
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={`${hostWithExtensionCaps} | Free Premium Link Generator | Cboxera.com`}
        meta={[
          {
            name: 'keywords',
            content: `${hostName} premium link generator, ${hostName} debrid, ${hostName} debrideur, ${pageContext.name} premium link generator, ${hostName} premium, ${pageContext.name} link generator`,
          },
          {
            name: 'description',
            content: `Are you tired of paying to download files from ${hostWithExtensionCaps}? Use Cboxera.com to download the files for free, with no limits and advertisements.`,
          },
        ]}
      />
      <div className="hostsection">
        <h1 className="lead text-center pt-4">
          <i className="fa fa-download text-danger pr-2"></i>
          Download from {capitalize(hostName)} at full speed with no limits and
          Ads with Cboxera.
          <div className="text-muted fnt-10 mt-2">
            <i
              title={hostWithExtension}
              className={`hosts_${
                hostWithExtension && hostWithExtension.replace(/\./g, '_')
              } pr-4`}
            ></i>
            {pageContext.name}
          </div>
        </h1>
        {/* show other hosts support ? */}

        <div className="container">
          <h2 className="question">
            1. Are you looking to download from {capitalize(hostName)} for free?
          </h2>
          <p>
            <i className="far fa-hand-point-right pr-2" />
            With Cboxera.com service you can skip the {capitalize(
              hostName
            )}{' '}
            download limits and payments by generating a premium download link
            for free and without any advertisements.
          </p>
          <h2 className="question">
            2. Are you looking to download files from another filehost?
          </h2>
          <p>
            <i className="far fa-hand-point-right pr-2" />
            <Link to={HOST_STATUS}>
              <strong>Click here</strong>
            </Link>{' '}
            to see the other 50+ filehosting sites we support.
          </p>
        </div>
        <HowToDownload headingAs="3" />
        <div className="container text-center">
          <Button size="lg" color="danger" onClick={handleDownloadClick}>
            Download from {capitalize(hostName)}
          </Button>
        </div>
      </div>
    </>
  )
}

FilehostTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default FilehostTemplate
