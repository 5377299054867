import { Component } from 'react'
import PropTypes from 'prop-types'
import scriptLoader from 'react-async-script-loader'

class StripeCheckout extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    stripe: null,
  }

  componentDidUpdate(prevProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props
    const { stripe } = this.state
    const { data } = this.props
    if (
      isScriptLoaded !== prevProps.isScriptLoaded &&
      isScriptLoadSucceed !== prevProps.isScriptLoadSucceed
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ stripe: window.Stripe(process.env.STRIPE_KEY) })
    }
    if (stripe && data && data.id) {
      stripe.redirectToCheckout({
        sessionId: data.id,
      })
    }
  }

  render() {
    return null
  }
}

StripeCheckout.defaultProps = {
  isScriptLoadSucceed: false,
  isScriptLoaded: false,
  data: {},
}

StripeCheckout.propTypes = {
  isScriptLoaded: PropTypes.bool,
  isScriptLoadSucceed: PropTypes.bool,
  data: PropTypes.object,
}

export default scriptLoader('https://js.stripe.com/v3/')(StripeCheckout)
